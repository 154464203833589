.loader.simple-loader {
	background-color: $body-bg;
	height: 100%;
	width: 100%;
	position: fixed;
	place-content: center;
	overflow: hidden !important;
	right: 0px;
	z-index: 1250;

	.loader-body {
		// background: url(../images/loader.gif) no-repeat scroll center center;
		background-size: 20%;
		width: 100%;
		height: 100%;
		position: relative;
	}
}

.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	/* Ensure the overlay is on top of everything */
}

.spinner-container {
	backdrop-filter: blur(5px);
	/* Apply blur effect to the background */
	background-color: rgba(255, 255, 255, 0.2);
	/* Semi-transparent background */
	border-radius: 8px;
	padding: 20px;
}